body,
html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

.coin {
  height: 160px;
  width: 160px;
  position: relative;
  margin: 20px auto;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.coin img {
  width: 160px;
}

.heads,
.tails {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.tails {
  transform: rotateY(180deg);
}

@keyframes spin-tails {
  0% {
    transform: rotateY(0);
  }

  100% {
    transform: rotateY(1980deg);
  }
}

@keyframes spin-heads {
  0% {
    transform: rotateY(0);
  }

  100% {
    transform: rotateY(2160deg);
  }
}

.nav-title {
  display: flex;
}

@media screen and (max-width: 992px) {
  .nav-title {
    display: none !important;
  }
}

.maincard-content {
  margin-top: 50px;
}

.connect-wallet {
  padding-top: 10px;
  padding-right: 14px;
  padding-left: 14px;
  padding-bottom: 10px;
  background: #0e76fd;
  font-weight: 700;
  cursor: pointer;
  font-size: 100%;
  color: white;
  border-radius: 15px;
  margin-right: 25px;
}

.connect-wallet:hover {
  background: #1060f3;
}

.img-style {
  margin-left: 1.2rem;
  height: 3.5rem;
  width: 3.rem;
  pointer-events: none;
}